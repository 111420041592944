import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';


export const subscriptionOfferService = createApi({
    reducerPath: 'subscriptionOfferService',
    baseQuery: baseQuery,
    tagTypes: ['SubscriptionOffers'],
    endpoints: (builder) => ({
        // 👉 Get All Products
        getSubscriptionOffers: builder.query({
            query: (arg) => {
                const { current, pageSize, q } = arg;

                console.log("arg", q)

                return {
                    url: '/offers',
                    params: {
                        ...arg,
                        page: current,
                        per_page: pageSize,
                        'filter[query]': q,
                    },
                };
            },
            providesTags: ['SubscriptionOffers'],
        }),

        // add new subcription


        // 👉 Create Subscription Deliveries
        createSubscription: builder.mutation({
            query: (payload) => {
                return {
                    url: `/offer/store`,
                    method: 'POST',
                    body: payload,
                };
            },
        }),

        // 👉 Get Subscription Details

        getSubscriptionDetails: builder.query({
            query: (id) => {
                return {
                    url: `/offer/${id}/edit`,
                };
            },
            providesTags: (result, error, arg) => [{ type: 'Subscription', id: arg }],
        }),

        // 👉 Update Subscription
        updateSubscription: builder.mutation({
            query: (payload) => {
                return {
                    url: `/offer/update`,
                    method: 'POST',
                    body: payload,
                };
            },
            
        }),
    }),

});

export const {
    useGetSubscriptionOffersQuery,
    useCreateSubscriptionMutation,
    useGetSubscriptionDetailsQuery,
    useUpdateSubscriptionMutation,
} = subscriptionOfferService;
