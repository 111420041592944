import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';

// Define the API service
export const RouteService = createApi({
  reducerPath: 'routeService',
  baseQuery: baseQuery,
  tagTypes: ['routePerm'],
  endpoints: (builder) => ({
    getAuthRoute: builder.query({
      query: () => ({
        url: '/roles/roles-menu-permission', // Ensure this URL is correct
      }),
    }),
  }),
});

// Export the query hook
export const { useGetAuthRouteQuery } = RouteService;
