import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './base.service';


export const popupOfferService = createApi({
    reducerPath: 'popupOfferService',
    baseQuery: baseQuery,
    tagTypes: ['PopupOffers'],
    endpoints: (builder) => ({
        // 👉 Get All Products
        getPopUpOffers: builder.query({
            query: (arg) => {
                const { current, pageSize, q } = arg;
        
                console.log("arg", arg);
        
                const request = {
                    url: '/popup-offers',
                    params: {
                        ...arg,
                        page: current,
                        per_page: pageSize,
                        'filter[query]': q,
                    },
                };
        
                console.log("Request URL:", request.url);
                console.log("Request Params:", request.params);
        
                return request;
            },
            providesTags: ['PopupOffers'],
        }),

        // add new subcription


        // 👉 Create Subscription Deliveries
        createPopUp: builder.mutation({
            query: (payload) => {
                return {
                    url: `/popup-offer/store`,
                    method: 'POST',
                    body: payload,
                };
            },
        }),

        // 👉 Get Subscription Details

        getPopUpDetails: builder.query({
            query: (id) => {
                return {
                    url: `popup-offer/${id}/edit`,
                };
            },
            providesTags: (result, error, arg) => [{ type: 'Subscription', id: arg }],
        }),

        // 👉 Update Subscription
        updatePopUpOffer: builder.mutation({
            query: (payload) => {
                return {
                    url: `popup-offer/update`,
                    method: 'POST',
                    body: payload,
                };
            },
            
        }),
    }),

});

export const {
    useGetPopUpOffersQuery,
    useCreatePopUpMutation,
    useGetPopUpDetailsQuery,
    useUpdatePopUpOfferMutation
} = popupOfferService;
