import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_BASE_URL } from '../configs/AppConfig';

const baseQuery = fetchBaseQuery({
  baseUrl: API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;

    headers.set('Accept', 'application/json');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export default baseQuery;
