import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import customer from './slices/customerSlice';
import menu from './slices/menuSlice';

import {
  carrierService,
  customerService,
  messageService,
  orderService,
  productsService,
  productSearchService,
  subscriptionService,
  sliderService,
  popupService,
  deliveryService,
  complainService,
  categoriesService,
  bottleService,
  couponsService,
  staffsService,
  walletsService,
  rewardsService,
  dropPointService,
  subscriptionOfferService,
  popupOfferService,
} from 'services';
import { customRouteSlice } from './slices/customRouteSlice';
import { RoleManagementServices } from 'services/Role.Service';
import { RouteService } from 'services/Route.Service';

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    customRoute: customRouteSlice.reducer,
    customer,
    menu,
    [customerService.reducerPath]: customerService.reducer,
    [subscriptionService.reducerPath]: subscriptionService.reducer,
    [carrierService.reducerPath]: carrierService.reducer,
    [messageService.reducerPath]: messageService.reducer,
    [productsService.reducerPath]: productsService.reducer,
    [productSearchService.reducerPath]: productSearchService.reducer,
    [orderService.reducerPath]: orderService.reducer,
    [sliderService.reducerPath]: sliderService.reducer,
    [popupService.reducerPath]: popupService.reducer,
    [deliveryService.reducerPath]: deliveryService.reducer,
    [complainService.reducerPath]: complainService.reducer,
    [categoriesService.reducerPath]: categoriesService.reducer,
    [bottleService.reducerPath]: bottleService.reducer,
    [couponsService.reducerPath]: couponsService.reducer,
    [staffsService.reducerPath]: staffsService.reducer,
    [walletsService.reducerPath]: walletsService.reducer,
    [rewardsService.reducerPath]: rewardsService.reducer,
    [dropPointService.reducerPath]: dropPointService.reducer,
    [subscriptionOfferService.reducerPath]: subscriptionOfferService.reducer,
    [popupOfferService.reducerPath]: popupOfferService.reducer,
    [RoleManagementServices.reducerPath]: RoleManagementServices.reducer,
    [RouteService.reducerPath]: RouteService.reducer,
    ...asyncReducers,
  });

  return combinedReducer(state, action);
};

export default rootReducer;
