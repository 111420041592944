import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

import {
  carrierService,
  customerService,
  deliveryService,
  messageService,
  orderService,
  productsService,
  productSearchService,
  sliderService,
  popupService,
  subscriptionService,
  complainService,
  categoriesService,
  bottleService,
  couponsService,
  staffsService,
  walletsService,
  rewardsService,
  dropPointService,
  subscriptionOfferService,
  popupOfferService,
} from 'services';
import { RoleManagementServices } from 'services/Role.Service';
import { RouteService } from 'services/Route.Service';

const middlewares = [
  customerService.middleware,
  carrierService.middleware,
  messageService.middleware,
  productsService.middleware,
  productSearchService.middleware,
  orderService.middleware,
  subscriptionService.middleware,
  sliderService.middleware,
  subscriptionOfferService.middleware,
  popupService.middleware,
  deliveryService.middleware,
  complainService.middleware,
  categoriesService.middleware,
  bottleService.middleware,
  couponsService.middleware,
  staffsService.middleware,
  walletsService.middleware,
  rewardsService.middleware,
  dropPointService.middleware,
  popupOfferService.middleware,
  RoleManagementServices.middleware,
  RouteService.middleware,
];

const store = configureStore({
  reducer: rootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(rootReducer(store.asyncReducers));

  return store;
};

export default store;
